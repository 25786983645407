import { Controller } from '@hotwired/stimulus'
import { Carousel }   from 'bootstrap'

export default class extends Controller {
  static targets = ['tickerCarousel']

  carousel      = null
  sequentialIds = {}

  connect() {
    this.carousel = new Carousel(this.tickerCarouselTarget, { interval: 4000, keyboard: false, pause: false, ride: 'carousel', touch: false, wrap: true })

    this.tickerCarouselTarget.addEventListener('slid.bs.carousel', event => {      
      let price = event.relatedTarget.querySelector('.ticker-price')

      if (price && price.classList.contains('ticker-blink')) {
        price.classList.remove('ticker-blink')
        price.animate([{ opacity: 1 }, { opacity: 0 }, { opacity: 1 }], 1500);
      }
    })

    this.carousel.cycle()
  }

  updateSequentialId(event) {
    let assetId      = event.detail.content.assetId
    let sequentialId = event.detail.content.sequentialId

    let needsToInit  = Object.keys(this.sequentialIds).indexOf(assetId) === -1
    let needsFocus   = false

    if (needsToInit) {
      this.sequentialIds[assetId] = sequentialId
    } else if (sequentialId > this.sequentialIds[assetId]) {
      this.sequentialIds[assetId] = sequentialId
      needsFocus                  = true
    }

    if (needsFocus) {
      let slide = this.tickerCarouselTarget.querySelector('#ticker-slide-' + assetId)
      let price = slide.querySelector('.ticker-price')

      price.classList.add('ticker-blink')

      this.carousel.to(parseInt(slide.dataset.sequence))
    }
  }
}
